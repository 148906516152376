.pageFilter-container {
  margin-top: 4px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #F5F5F5;
  padding-bottom: 8px;
}
.pageFilter-container .pageheader {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  height: 25px;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 25px;
  letter-spacing: 0.4px;
  color: var(--header-text-color);
}
.pageFilter-container .applied-filter-container {
  padding-top: 10px;
  padding-bottom: 10px;
}
.pageFilter-container .applied-filter-container .applied-filter-header {
  font-weight: bold;
  padding-right: 10px;
}
.pageFilter-container .applied-filter-container .applied-filter-text {
  font-weight: bold;
  padding-right: 10px;
  padding-left: 10px;
}
.pageFilter-container .applied-filter-value div {
  margin-bottom: 5px;
  margin-right: 2px;
}
.pageFilter-container .MuiFormControl-root,
.pageFilter-container ::before,
.pageFilter-container input,
.pageFilter-container label,
.pageFilter-container span,
.pageFilter-container .MuiChip-root,
.pageFilter-container .MuiAutocomplete-popper,
.pageFilter-container .MuiTypography-root,
.pageFilter-container .MuiMenu-list,
.pageFilter-container .MuiAutocomplete-option,
.pageFilter-container .MuiChip-label {
  font-size: 0.868rem !important;
}
.MuiAutocomplete-option {
  font-size: 0.768rem !important;
}
.MuiFormControl-fullWidth {
  background-color: #ffff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  position: static;
  width: 368px;
  left: 0px;
  /* Inside Auto Layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 4px 0px;
}
.filter-action-group {
  margin-top: 5px;
  margin-bottom: 5px;
}
.divRow {
  margin-left: -22px !important;
  width: 100%;
}
.alignText-Right .filter-label {
  text-align: right;
}
.alignText-Center .filter-label {
  text-align: center;
}
.filter-label {
  padding-top: 10px;
  font-style: normal;
  font-weight: 400;
  /* identical to box height */
  letter-spacing: 0.2px;
  font-size: 13px;
  color: #003D69;
}
.filter-icon {
  margin-top: 1rem;
  margin-left: 107px;
}
.btn-clear {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  position: static;
  height: 32px;
  border-radius: 2px;
  /* Inside Auto Layout */
  flex: none;
  order: 3;
  flex-grow: 0;
  margin: 0px 10px;
}
.btn-font-clear {
  color: #004EA8 !important;
  padding: 5px 9px !important;
  font-size: 14px !important;
}
.span-filter {
  margin-right: 3px;
  padding: 0.4rem;
  background-color: lightgray;
}
.filter-pane {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px;
  position: static;
  height: 28px;
  left: 94px;
  top: 2px;
  background: #D9D9D6;
  border-radius: 2px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 1px 2px;
}
.MuiChip-outlinedPrimary {
  color: #004EA8 !important;
  border: 0px solid !important;
}
.filter-chip {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  position: static;
  height: 28px;
  left: 94px;
  top: 2px;
  /* Gray 5 */
  background: #D9D9D6;
  border-radius: 2px;
  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
}
.active-filter-label {
  position: static;
  height: 20px;
  left: 0px;
  right: 1084px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  /* Gray 3 */
  color: #53565A;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
li {
  padding: 8px 12px;
}
li:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}
.container {
  position: relative;
  display: inline-block;
}
.button {
  padding: 0;
  width: 50px;
  border: 0;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  outline: 0;
  font-size: 40px;
}
.header-container {
  padding-left: 2px;
  padding-right: 25px;
}
