/* The container */
.Radiocontainer {
  display: inline-block;
  position: relative;
  padding: 6px 24px;
  cursor: pointer;
  font-size: 0.9rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default radio button */
.Radiocontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border-radius: 50%;
  border: 1px solid #00b0b9;
}
/* On mouse-over, add a grey background color */
.Radiocontainer:hover input ~ .checkmark {
  background-color: #00b0b9;
}
/* When the radio button is checked, add a blue background */
.Radiocontainer input:checked ~ .checkmark {
  background-color: #00b0b9;
}
/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the indicator (dot/circle) when checked */
.Radiocontainer input:checked ~ .checkmark:after {
  display: block;
}
/* Style the indicator (dot/circle) */
.Radiocontainer .checkmark:after {
  top: 5px;
  left: 5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: white;
}
.slider-radio-group {
  margin-left: 8px;
}
@media only screen and (max-width: 480px) {
  .slider-radio-group {
    text-align: left;
  }
}
