.nav-container {
  display: flex;
  height: 48px;
  background: #07406d;
}
.nav-logo-div {
  padding: 8px;
}
.stream-logo {
  max-width: 120px;
}
.appbar-background {
  background-image: url("../../../public/img/common/header-image.jpg");
}
.user-initial {
  font-size: 14px !important;
}
.user-initial-inner {
  flex: 0 0 auto;
  color: #f0f0f0;
  /* padding   : 40%; */
  overflow: visible;
  /* font-size : 1.5rem; */
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  line-height: 39px;
  background: #07406d;
  width: 40px;
  height: 40px;
  margin: auto;
}
.user-details-inner {
  text-align: center;
  padding: 5px 10px;
  color: var(--common--Text--color);
}
.iconbutton-extend {
  border: 1px solid #ffffff !important;
  padding: 9px !important;
  margin-top: 6px !important;
  margin-left: 20px !important;
  font-weight: 700;
}
