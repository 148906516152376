html {
  --addExtension-border: #fff;
  --addExtension-extensionRow-color: #fff;
  --addExtension-extensionRow-border-bottom: #fff;
  --addExtension-extensionRow-button-background-color: #1D4B6E;
  --addExtension-labelCell-border-right: #cccccc;
  --addExtension-after-border-color: rgba(136, 183, 213, 0);
  --addExtension-after-border-bottom-color: #05375E;
  --addExtension-before-border-color: rgba(194, 225, 245, 0);
  --addExtension-before-border-bottom-color: #c2e1f5;
  --maintenance-card-main-body-webkit-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-moz-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-header-background-color: #365E7E;
  --common-Rpt--even--color: #004EA8;
  --maintenance-card-body-border: #365E7E;
  --maintenance-card-header-title-color: #f6f6f6;
  --filter_title-color: #4c4c4c;
  --react_autocomplete_container-input-background-color: #EBEDED;
  --react_autocomplete_container-input-border: #CCCCCC;
  --react_autocomplete_container-a-div-color: #FFFFFF;
  --react_autocomplete_container-a-div-border: #FFFFFF;
  --react_autocomplete_container-a-div-background-color: #05375e;
  --react_autocomplete_container-a-div-hover-background-color: #05375e;
  --simpleButton-background-color: #00B0B9;
  --simpleButton-color: #FFFFFF;
  --simpleButton-hover-background-color: #00B0B9;
  --simpleButton-selected-background-color: #00B0B9;
  --simpleButton-selected-box-shadow: rgba(0, 0, 0, 0.3);
}
html[data-theme="Light"] {
  --addExtension-border: #fff;
  --addExtension-extensionRow-color: #fff;
  --addExtension-extensionRow-border-bottom: #fff;
  --addExtension-extensionRow-button-background-color: #1D4B6E;
  --addExtension-labelCell-border-right: #cccccc;
  --addExtension-after-border-color: rgba(136, 183, 213, 0);
  --addExtension-after-border-bottom-color: #05375E;
  --addExtension-before-border-color: rgba(194, 225, 245, 0);
  --addExtension-before-border-bottom-color: #c2e1f5;
  --maintenance-card-main-body-webkit-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-moz-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-header-background-color: #FFFFFF;
  --maintenance-card-body-border: #FFFFFF;
  --maintenance-card-header-title-color: #000000;
  --filter_title-color: #000000;
  --react_autocomplete_container-input-background-color: #EBEDED;
  --react_autocomplete_container-input-border: #000000;
  --react_autocomplete_container-a-div-color: #FFFFFF;
  --react_autocomplete_container-a-div-border: #FFFFFF;
  --react_autocomplete_container-a-div-background-color: #FFFFFF;
  --react_autocomplete_container-a-div-hover-background-color: #EBEDED;
  --simpleButton-background-color: #FFFFFF;
  --simpleButton-color: #000000;
  --simpleButton-hover-background-color: #888787;
  --simpleButton-selected-background-color: #636363;
  --simpleButton-selected-box-shadow: rgba(0, 0, 0, 0.3);
}
html[data-theme="Dark"] {
  --addExtension-border: #fff;
  --addExtension-extensionRow-color: #fff;
  --addExtension-extensionRow-border-bottom: #fff;
  --addExtension-extensionRow-button-background-color: #1D4B6E;
  --addExtension-labelCell-border-right: #cccccc;
  --addExtension-after-border-color: rgba(136, 183, 213, 0);
  --addExtension-after-border-bottom-color: #05375E;
  --addExtension-before-border-color: rgba(194, 225, 245, 0);
  --addExtension-before-border-bottom-color: #c2e1f5;
  --maintenance-card-main-body-webkit-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-moz-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-header-background-color: #000000;
  --maintenance-card-body-border: #000000;
  --maintenance-card-header-title-color: #f6f6f6;
  --filter_title-color: #EBEDED;
  --react_autocomplete_container-input-background-color: #000000;
  --react_autocomplete_container-input-border: #CCCCCC;
  --react_autocomplete_container-a-div-color: #000000;
  --react_autocomplete_container-a-div-border: #000000;
  --react_autocomplete_container-a-div-background-color: #000000;
  --react_autocomplete_container-a-div-hover-background-color: #EBEDED;
  --simpleButton-background-color: #000000;
  --simpleButton-color: #FFFFFF;
  --simpleButton-hover-background-color: #888787;
  --simpleButton-selected-background-color: #636363;
  --simpleButton-selected-box-shadow: rgba(0, 0, 0, 0);
}
.underDevelopmentText {
  position: absolute;
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #05375e;
  font-weight: 600;
}
.react-table-landing table {
  border-spacing: 0;
  color: #ffffff;
  flex: auto 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  font-size: 0.94rem;
}
.react-table-landing table thead,
.react-table-landing table caption,
.react-table-landing table tfoot {
  background: #cedce9;
  font-weight: 600;
}
.react-table-landing table caption {
  text-align: left;
  border-bottom: 1px solid #cccccc;
  height: 28px;
  padding: 5px;
}
.react-table-landing table tr {
  background-color: white !important;
  color: #004EA8 !important;
  border-bottom: 1px solid #ffffff;
  min-width: 100%;
}
.react-table-landing table tbody tr {
  cursor: pointer;
}
.react-table-landing table tbody tr.non-clickable {
  cursor: default !important;
  pointer-events: none;
}
.react-table-landing table tbody tr:nth-child(even) {
  background-color: #ffff !important;
}
.react-table-landing table tbody tr:nth-child(odd) {
  background-color: #F5F5F5 !important;
}
.react-table-landing table tbody tr.exapandableRow {
  font-weight: 600;
}
.react-table-landing table tbody tr.expandedSubRowsLevel1 td.subRowsInCol span {
  margin-left: 8px;
}
.react-table-landing table tbody tr.expandedSubRowsLevel1.expandedSubRowsLevel2 td.subRowsInCol span {
  margin-left: 16px;
}
.react-table-landing table tbody tr:not(.exapandableRow):not(.no-tr-hover):hover {
  background: #05375E;
}
.react-table-landing table tbody tr.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 100%;
}
.react-table-landing table tbody tr.no-data td {
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-table-landing table tbody tr :last-child td {
  border-bottom: 0;
}
.react-table-landing table th,
.react-table-landing table td {
  margin: 0;
  padding: 0.2rem;
  text-align: left;
  flex: 100 0 auto !important;
  overflow: hidden;
}
.react-table-landing table th :last-child,
.react-table-landing table td :last-child {
  border-right: 0;
}
.react-table-landing table th span,
.react-table-landing table td span {
  text-overflow: ellipsis;
}
.react-table-landing table th .expandGroupTd,
.react-table-landing table td .expandGroupTd {
  display: flex;
}
.react-table-landing table th .expandGroupTd .expandedIcon,
.react-table-landing table td .expandGroupTd .expandedIcon {
  padding: 0 4px;
}
.react-table-landing table th .expandGroupTd .expandedIcon svg,
.react-table-landing table td .expandGroupTd .expandedIcon svg {
  font-size: 1.2rem;
}
.react-table-landing table th .resizer,
.react-table-landing table td .resizer {
  display: inline-block;
  width: 8px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
}
.react-table-landing table th .resizer.isResizing,
.react-table-landing table td .resizer.isResizing {
  background: #27608e;
  opacity: 0.4;
}
.react-table-landing table th .maturityNumber,
.react-table-landing table td .maturityNumber {
  width: 50px;
  height: 18px;
  color: #ffffff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border-radius: 10px;
}
.react-table-landing table th .maturityNumber.alertText,
.react-table-landing table td .maturityNumber.alertText {
  background: #e04747;
}
.react-table-landing table th .maturityNumber.warningText,
.react-table-landing table td .maturityNumber.warningText {
  background: #eca420;
}
.react-table-landing table th .maturityNumber.okText,
.react-table-landing table td .maturityNumber.okText {
  background: #197319;
}
.react-table-landing table th .editIcon,
.react-table-landing table td .editIcon {
  margin: 0 10px;
}
.react-table-landing table td {
  text-overflow: ellipsis;
}
.react-table-landing table td.columnHighlighter {
  background: #a9b9c9;
}
.react-table-landing table td .info-tooltip {
  margin-left: 6px;
}
.react-table-landing table td .info-tooltip svg {
  color: #00b0b9;
}
.react-table-landing table th {
  white-space: pre-wrap;
  font-weight: 500;
}
.react-table-landing table .headerRightAlign,
.react-table-landing table .valueRightAlign {
  text-align: right;
}
.react-table-landing table .headerCenterAlign,
.react-table-landing table .valueCenterAlign {
  text-align: center;
}
.react-table-landing table .subRowsexpander {
  padding: 0;
}
.react-table-landing table .subRowsexpander .svgIcon {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.react-table-landing table .subRowsexpander .svgIcon svg {
  font-size: 1.2rem;
}
.react-table-landing table .headerCol {
  background-color: #cedce9;
  font-weight: 500;
}
@media screen and (max-width: 1024px) {
  .react-table-landing table td,
  .react-table-landing table th {
    border-left: 1px solid rgba(176, 199, 218, 0.3);
    border-top: 0px none;
    border-bottom: 0px none;
  }
}
.react-table-landing table.sticky thead,
.react-table-landing table.sticky tfoot {
  position: sticky;
  z-index: 1;
}
.react-table-landing table.sticky thead {
  top: 0;
}
.react-table-landing table.sticky tfoot {
  bottom: 0;
}
.react-table-landing table.sticky tfoot td {
  background: inherit;
}
.react-table-landing table.sticky tbody {
  position: relative;
  z-index: 0;
}
.react-table-landing table.sticky [data-sticky-td] {
  position: sticky !important;
  background: inherit;
}
.tableFooter {
  background-color: #f1f1f1;
  padding: 8px 0px;
  width: 100%;
  font-weight: 500;
  text-align: left;
  font-size: 16px;
  color: #2c3e50;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button {
  border: none;
  padding: 7px 14px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 4px;
  margin-left: 4px;
}
.activeButton {
  color: white;
  background: #185adb;
}
.inactiveButton {
  color: #2c3e50;
  background: #f9f9f9;
}
