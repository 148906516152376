.standardError {
  color: #ffffff !important;
  background-color: #e45e6d !important;
  border: 1px solid #fff !important;
}
.message {
  color: #ffffff !important;
  background-color: #b17d41 !important;
  border: 1px solid #fff !important;
}
.info {
  color: #004EA8 !important;
  background-color: #e0e0e0 !important;
  border: 1px solid #fff !important;
}
.success {
  color: #ffffff !important;
  background-color: #5da56a !important;
  border: 1px solid #fff !important;
}
.warning {
  color: #ffffff !important;
  background-color: #eaa2b1 !important;
  border: 1px solid #fff !important;
}
.editable {
  margin-top: 0px !important;
  border: #c1c1c1 1px solid !important;
}
.editable .MuiFormControl-marginNormal {
  margin-top: 0px !important;
}
.editable input {
  background-color: #FFFFFF !important;
  color: var(--common--Text--color) !important;
}
.editable svg {
  fill: var(--common--Text--color) !important;
}
.sticky-wrapper {
  position: relative;
  height: 100%;
  /* We need to change this value */
}
.sticky thead:not(.normalheader) {
  position: absolute;
  z-index: 1;
}
.stickyHeader {
  overflow-x: hidden;
}
.stickyHeader th {
  position: absolute;
  top: 0;
  /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px #c2e1f5;
}
.pager {
  float: right;
}
.notop-action-button {
  margin-top: 5px !important;
  margin-left: 10px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 8px 16px; */
  position: static;
  /* width: 148px; */
  height: 32px;
  left: 0px;
  top: 0px;
  background-color: #00B0B9 !important;
  border-radius: 2px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.notop-action-button svg {
  fill: #00B0B9 !important;
}
.notop-action-button .tool-icon-text {
  position: static;
  height: 16px;
  left: 16px;
  top: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.action-button {
  margin-top: 15px !important;
  margin-left: 10px !important;
  /* color: #000000 !important; */
  /* background-color: #004EA8 !important; */
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: center; */
  /* align-items: center; */
  /* padding: 8px 16px; */
  /* position: static; */
  /* height: 32px; */
  /* left: 0px; */
  /* top: 0px; */
  /* border-radius: 2px; */
  /* flex: none; */
  /* order: 0; */
  /* flex-grow: 0; */
  /* margin: 0px 16px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 8px 16px; */
  position: static;
  /* width: 148px; */
  height: 32px;
  left: 0px;
  top: 0px;
  background-color: #00B0B9 !important;
  border-radius: 2px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.action-button svg {
  fill: #00B0B9 !important;
}
.action-button .tool-icon-text {
  position: static;
  height: 16px;
  left: 16px;
  top: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #FFFFFF;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.action-button-cancel {
  margin-top: 15px !important;
  margin-left: 10px !important;
  /* color: #000000 !important; */
  /* background-color: #004EA8 !important; */
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: center; */
  /* align-items: center; */
  /* padding: 8px 16px; */
  /* position: static; */
  /* height: 32px; */
  /* left: 0px; */
  /* top: 0px; */
  /* border-radius: 2px; */
  /* flex: none; */
  /* order: 0; */
  /* flex-grow: 0; */
  /* margin: 0px 16px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 8px 16px; */
  position: static;
  /* width: 148px; */
  height: 32px;
  left: 0px;
  top: 0px;
  border-radius: 2px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.action-button-cancel svg {
  fill: #00B0B9 !important;
}
.action-button-cancel .tool-icon-text {
  position: static;
  height: 16px;
  left: 16px;
  top: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #FFFFFF;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.action-button-cancel .tool-icon-label {
  position: static;
  height: 16px;
  left: 16px;
  top: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #00B0B9;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.editReport {
  max-width: 21% !important;
  flex-basis: 14% !important;
}
.editReportDenseCompSet {
  max-width: 10% !important;
  flex-basis: 10% !important;
}
.editReportDense {
  max-width: 11% !important;
  flex-basis: 11% !important;
}
.editReportSpreadOut {
  max-width: 20% !important;
  flex-basis: 20% !important;
}
