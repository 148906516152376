.reportTable .multi-pay-period {
  border-bottom: 1px solid var(--common--Text--color) !important;
  padding: 2px 0px;
}
.reportTable .parent-row td {
  font-weight: bold !important;
}
.reportTable .rpt-even {
  background-color: #e9edf0 !important;
  color: var(--common--Text--color) !important;
  font-size: 0.768rem;
}
.reportTable .rpt-odd {
  background-color: #ffffff !important;
  color: var(--common--Text--color) !important;
}
.reportTable .rpt-topBorder {
  border-top: 1px solid var(--common--Text--color) !important;
}
.reportTable .rpt {
  height: auto !important;
  padding: 3px 1px;
  font-size: 0.768rem !important;
  line-height: 1rem !important;
  border-bottom: 0px solid var(--common--Text--color) !important;
}
.reportTable .rpt:not(.rpt-topBorder) {
  border: 0px solid var(--common--Text--color) !important;
}
.reportTable tr:nth-child(even) td:not(.rpt) {
  background-color: #e9edf0;
  color: var(--common--Text--color) !important;
  height: auto !important;
  padding: 3px 20px;
  height: 10px !important;
}
.reportTable tr:nth-child(odd) {
  height: 10px !important;
}
.reportTable tr:nth-child(odd) td:not(.rpt) {
  background-color: #ffffff;
  color: var(--common--Text--color) !important;
  height: auto !important;
  padding: 3px 20px;
}
.reportTable th {
  height: 10px !important;
  padding: 3px 2px !important;
  background-color: #064e88 !important;
}
.reportTable th div {
  min-width: 96px;
}
.reportTable .sticky {
  position: sticky;
  left: 0px;
  min-width: 150px;
  background: white;
}
.reportTable .sticky-1 {
  position: sticky;
  left: 150px;
  background: #686464;
  min-width: 150px;
}
.reportTable .sticky-2 {
  position: sticky;
  left: 300px;
  background: white;
  min-width: 150px;
}
.reportTable .sticky-3 {
  position: sticky;
  left: 450px;
  background: white;
  min-width: 150px;
}
.reportTable .sticky-4 {
  position: sticky;
  left: 600px;
  background: white;
  min-width: 150px;
}
.reportTable .sticky-5 {
  position: sticky;
  left: 750px;
  background: white;
  min-width: 150px;
}
.reportTable .sticky-6 {
  position: sticky;
  left: 900px;
  background: white;
  min-width: 150px;
}
.reportTable .right-border {
  border-right: 2px solid white;
}
.date-mt {
  margin-top: -7px !important;
}
.header {
  margin-left: 10px !important;
  color: #003865 !important;
  font-size: 1.5rem !important;
}
.flat-rate {
  min-width: 120px !important;
}
.btn {
  margin-right: 10px !important;
  height: 46px !important;
  background-color: #05375e !important;
}
.btn-export-excel {
  color: white;
}
.item-big {
  margin-top: 30px;
  font-size: 1.5rem;
  color: #003865;
}
.head {
  line-height: 0;
}
.MuiTableCell-root {
  border: none;
  line-height: none;
}
.pay-period {
  background: #c0ced9;
  border-radius: 12px;
  width: 185px;
  margin-left: 224px;
}
.buttons {
  margin-left: auto;
  margin-top: 10px;
}
.MuiGrid-container {
  width: 18%;
  display: flex;
  flex-wrap: wrap;
}
.rpt-header-icon {
  color: #fff;
}
.rpt-header-icon span svg {
  fill: #fff !important;
}
.MuiTableCell-head {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  line-height: 0;
}
.percent-text {
  display: inline-block;
  min-width: 50px;
  text-align: right !important;
  padding-right: 30px;
}
.addwidget-container {
  margin-top: 5rem;
  background-color: white;
  min-height: 60%;
  border-radius: 0px;
  border: 0px solid whitesmoke;
  margin-right: 10px;
  color: var(--common--Text--color);
  padding-bottom: 15px;
}
.addwidget-container .header1 div {
  font-size: 30px;
  margin-top: 16px;
  border-bottom: 0px solid #064c8a;
  width: 94%;
  font-weight: normal;
  padding: 0px 0px;
}
.addwidget-container .header1 {
  padding: 10px;
  background: #003865;
}
.header1 {
  color: #fff;
}
.pay-periodtwo {
  font-size: 0.768rem;
  margin-top: 20px;
  margin-bottom: 22px;
  font-weight: bold;
  width: 5vw;
}
.slide_dateppicker {
  width: 25vw;
  justify-content: center;
  margin-left: 2rem;
  height: 12vh;
}
.group_all {
  margin-left: 33px;
}
.group__title {
  text-align: left;
  margin-top: 15px;
  font-size: 0.768rem;
  font-weight: bold;
  height: 3vh;
}
.group_available {
  float: left;
  margin-left: 7rem;
}
.group_applied {
  float: right;
  margin-right: 7rem;
}
.text__area {
  display: flex;
  margin-top: 38px;
  height: 24vh;
}
.addwidget-container .widget-holder {
  line-height: 3px;
}
.box__shadow {
  box-shadow: 0px -5px 0px 0px rgba(149, 152, 174, 0.23);
  -webkit-box-shadow: 0px -5px 0px 0px rgba(149, 152, 174, 0.23);
  -moz-box-shadow: 0px -5px 0px 0px rgba(149, 152, 174, 0.23);
  border: 0.1px solid #9598ae3b;
  width: 135px;
  height: 23vh;
  border-radius: 3px;
}
.advanced__buttons {
  margin-left: 257px;
  float: left;
  margin-top: 1em;
}
.arrow__buttons {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}
.arrow__button:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
}
.arrow__button {
  width: 10px;
  border: 1px solid #d2cece;
  color: inherit;
  cursor: pointer;
  margin-top: 0.7rem;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  color: #003865;
}
.MuiButtonBase-root {
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}
.horizontal__line {
  border: 1px solid #E8F0F2;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
.MuiGrid-spacing-xs-3 {
  width: calc(100% + 24px);
  margin: 10px;
}
.MuiFormControl-marginNormal {
  margin-top: 2px !important;
}
.pageheader-container .pageheader {
  padding-top: 5px;
  padding-bottom: 5px;
  height: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.4px;
  color: var(--header-text-color);
}
.MuiGrid-spacing-xs-3 {
  width: calc(100% + 24px);
  margin: -12px;
  margin-left: 10px;
}
.left-margin {
  margin-left: 1.3rem;
}
