.pageheader-container .pageheader {
  padding-top: 5px;
  padding-bottom: 5px;
  height: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.4px;
  color: var(--header-text-color);
}
.pageheader-container .action-label {
  font-size: 0.788rem;
}
.innerHeader .pageheader-container .pageheader {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  height: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: var(--header-text-color);
}
.innerHeader .pageheader-container .action-label {
  font-size: 0.788rem;
}
.pageheader-padding {
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: static;
  left: 16px;
  top: 16px;
}
.sectionheader-container .sectionheader {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  height: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.4px;
  color: #004EA8;
}
.sectionheader-container .action-label {
  font-size: 0.788rem;
}
.header-btn {
  padding-right: 0px;
  position: static;
  cursor: pointer;
  /* width: 87px; */
  height: 26px;
  left: calc(50% - 87px/2 - 276.5px);
  top: calc(50% - 23px/2);
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.4px;
  color: #00B0B9;
  mix-blend-mode: normal;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 2px;
  border: 0px #ffff;
  background-color: #ffff;
}
