.footer-wrapper {
  width: 100%;
  height: 40px;
  color: #ffffff;
  position: fixed;
  bottom: 0px;
  background-image: url("../../../public/img/common/header-image.jpg");
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  font-weight: 300;
  z-index: 9999;
}
.footer-wrapper .footer-left-div {
  text-align: left;
  width: 20%;
  margin-left: auto;
  margin-right: auto;
}
.footer-wrapper .footer-middle-div {
  text-align: center;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
  font-size: 13px;
}
.footer-wrapper .footer-right-div {
  text-align: right;
  width: 20%;
  margin-left: auto;
  margin-right: auto;
}
