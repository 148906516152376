.info-box-container {
  display: inline-block;
  margin: 0.8rem 1rem;
  background-color: var(--info-box-container-background-color);
  font-weight: normal;
  color: var(--info-box-container-color);
  -webkit-box-shadow: -1px 1px 7px -1px var(--info-box-container-webkit-box-shadow);
  -moz-box-shadow: -1px 1px 7px -1px var(--info-box-container-moz-box-shadow);
  box-shadow: -1px 1px 7px -1px var(--info-box-container-box-shadow);
  width: 172px;
  height: 86px;
}
.info-box-container .display-text {
  text-align: left;
  padding-left: 20px;
  top: 12px;
  position: relative;
  font-size: 0.95rem;
  font-weight: 600;
}
.info-box-container .single-section {
  position: relative;
  display: grid;
  top: 26px;
}
.info-box-container .single-section .single-value-text {
  text-align: left;
  font-size: 1.3rem;
  color: var(--text-color);
  font-weight: 500;
  padding-left: 20px;
}
.info-box-container .single-section .single-display-text {
  text-align: left;
  font-size: 0.8rem;
  color: var(--text-color);
  padding-top: 0.7rem;
  padding-left: 20px;
}
.info-box-container .regular-display-text {
  font-size: 0.7rem;
}
@media only screen and (max-width: 480px) {
  .info-box-container {
    margin: 0.5rem 1.2rem;
  }
}
