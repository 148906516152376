.tabs {
  margin: 20px 10px 10px 10px;
  padding: 3px;
  display: flex;
}
.tabs .tab-capsule {
  padding: 7px;
  border: 1px solid white;
  border-bottom: none;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  text-align: center;
  background-color: #064473;
  color: #ffffff;
  cursor: pointer;
}
.tabs .selected,
.tabs .tab-capsule:hover {
  background-color: #D3D3D3;
  color: #064473;
}
.tabs-nomargin {
  padding: 15px;
  display: flex;
}
.tabs-nomargin .tab-capsule {
  padding: 9px;
  border: 1px solid white;
  border-bottom: none;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  text-align: center;
  background-color: #D3D3D3;
  color: #064473;
  cursor: pointer;
}
.tabs-nomargin .selected,
.tabs-nomargin .tab-capsule:hover {
  background-color: #064473;
  color: #ffffff;
}
.tabs-forStats {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 0px;
  display: flex;
}
.tabs-forStats .tab-capsule {
  padding: 9px;
  border: 1px solid white;
  border-bottom: none;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  text-align: center;
  background-color: #D3D3D3;
  color: #064473;
  cursor: pointer;
}
.tabs-forStats .selected,
.tabs-forStats .tab-capsule:hover {
  background-color: #064473;
  color: #ffffff;
}
div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}
div.scroll {
  padding: 10px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
