html {
  --addExtension-border: #fff;
  --addExtension-extensionRow-color: #fff;
  --addExtension-extensionRow-border-bottom: #fff;
  --addExtension-extensionRow-button-background-color: #1D4B6E;
  --addExtension-labelCell-border-right: #cccccc;
  --addExtension-after-border-color: rgba(136, 183, 213, 0);
  --addExtension-after-border-bottom-color: #05375E;
  --addExtension-before-border-color: rgba(194, 225, 245, 0);
  --addExtension-before-border-bottom-color: #c2e1f5;
  --maintenance-card-main-body-webkit-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-moz-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-header-background-color: #365E7E;
  --common-Rpt--even--color: #004EA8;
  --maintenance-card-body-border: #365E7E;
  --maintenance-card-header-title-color: #f6f6f6;
  --filter_title-color: #4c4c4c;
  --react_autocomplete_container-input-background-color: #EBEDED;
  --react_autocomplete_container-input-border: #CCCCCC;
  --react_autocomplete_container-a-div-color: #FFFFFF;
  --react_autocomplete_container-a-div-border: #FFFFFF;
  --react_autocomplete_container-a-div-background-color: #05375e;
  --react_autocomplete_container-a-div-hover-background-color: #05375e;
  --simpleButton-background-color: #00B0B9;
  --simpleButton-color: #FFFFFF;
  --simpleButton-hover-background-color: #00B0B9;
  --simpleButton-selected-background-color: #00B0B9;
  --simpleButton-selected-box-shadow: rgba(0, 0, 0, 0.3);
}
html[data-theme="Light"] {
  --addExtension-border: #fff;
  --addExtension-extensionRow-color: #fff;
  --addExtension-extensionRow-border-bottom: #fff;
  --addExtension-extensionRow-button-background-color: #1D4B6E;
  --addExtension-labelCell-border-right: #cccccc;
  --addExtension-after-border-color: rgba(136, 183, 213, 0);
  --addExtension-after-border-bottom-color: #05375E;
  --addExtension-before-border-color: rgba(194, 225, 245, 0);
  --addExtension-before-border-bottom-color: #c2e1f5;
  --maintenance-card-main-body-webkit-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-moz-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-header-background-color: #FFFFFF;
  --maintenance-card-body-border: #FFFFFF;
  --maintenance-card-header-title-color: #000000;
  --filter_title-color: #000000;
  --react_autocomplete_container-input-background-color: #EBEDED;
  --react_autocomplete_container-input-border: #000000;
  --react_autocomplete_container-a-div-color: #FFFFFF;
  --react_autocomplete_container-a-div-border: #FFFFFF;
  --react_autocomplete_container-a-div-background-color: #FFFFFF;
  --react_autocomplete_container-a-div-hover-background-color: #EBEDED;
  --simpleButton-background-color: #FFFFFF;
  --simpleButton-color: #000000;
  --simpleButton-hover-background-color: #888787;
  --simpleButton-selected-background-color: #636363;
  --simpleButton-selected-box-shadow: rgba(0, 0, 0, 0.3);
}
html[data-theme="Dark"] {
  --addExtension-border: #fff;
  --addExtension-extensionRow-color: #fff;
  --addExtension-extensionRow-border-bottom: #fff;
  --addExtension-extensionRow-button-background-color: #1D4B6E;
  --addExtension-labelCell-border-right: #cccccc;
  --addExtension-after-border-color: rgba(136, 183, 213, 0);
  --addExtension-after-border-bottom-color: #05375E;
  --addExtension-before-border-color: rgba(194, 225, 245, 0);
  --addExtension-before-border-bottom-color: #c2e1f5;
  --maintenance-card-main-body-webkit-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-moz-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-header-background-color: #000000;
  --maintenance-card-body-border: #000000;
  --maintenance-card-header-title-color: #f6f6f6;
  --filter_title-color: #EBEDED;
  --react_autocomplete_container-input-background-color: #000000;
  --react_autocomplete_container-input-border: #CCCCCC;
  --react_autocomplete_container-a-div-color: #000000;
  --react_autocomplete_container-a-div-border: #000000;
  --react_autocomplete_container-a-div-background-color: #000000;
  --react_autocomplete_container-a-div-hover-background-color: #EBEDED;
  --simpleButton-background-color: #000000;
  --simpleButton-color: #FFFFFF;
  --simpleButton-hover-background-color: #888787;
  --simpleButton-selected-background-color: #636363;
  --simpleButton-selected-box-shadow: rgba(0, 0, 0, 0);
}
.underDevelopmentText {
  position: absolute;
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #05375e;
  font-weight: 600;
}
.confirmPopup {
  z-index: 99999 !important;
}
.confirmPopup .header {
  font-size: 1.1rem;
  padding: 3px;
  border-color: #ccc;
}
.confirmPopup .confirmMsg {
  font-size: 0.9rem;
  padding: 12px 10px;
}
.confirmPopup .MuiDialogTitle-root {
  background: #EFEFEF;
  padding: 4px 12px;
}
.confirmPopup .MuiDialogActions-root {
  align-self: center;
}
.confirmPopup .MuiDialogActions-root button {
  background: var(--simpleButton-background-color);
  color: var(--simpleButton-color);
  font-weight: 600;
}
.confirmPopup .MuiDialogActions-root button:hover {
  background: var(--simpleButton-hover-background-color);
}
.confirmPopup .MuiDialogContentText-root {
  font-size: 16px;
  line-height: 1.2 !important;
  color: #000000;
}
.confirmPopup .MuiTypography-h6 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #4c4343;
}
.confirmPopup .MuiIconButton-root {
  top: -4px;
  right: -4px;
}
.confirmPopup .MuiDialog-paper {
  min-width: 40%;
  overflow-y: unset;
  border: 2px solid #6b6b6b;
  border-radius: 5px;
}
