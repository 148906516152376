html {
  --addExtension-border: #fff;
  --addExtension-extensionRow-color: #fff;
  --addExtension-extensionRow-border-bottom: #fff;
  --addExtension-extensionRow-button-background-color: #1D4B6E;
  --addExtension-labelCell-border-right: #cccccc;
  --addExtension-after-border-color: rgba(136, 183, 213, 0);
  --addExtension-after-border-bottom-color: #05375E;
  --addExtension-before-border-color: rgba(194, 225, 245, 0);
  --addExtension-before-border-bottom-color: #c2e1f5;
  --maintenance-card-main-body-webkit-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-moz-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-header-background-color: #365E7E;
  --common-Rpt--even--color: #004EA8;
  --maintenance-card-body-border: #365E7E;
  --maintenance-card-header-title-color: #f6f6f6;
  --filter_title-color: #4c4c4c;
  --react_autocomplete_container-input-background-color: #EBEDED;
  --react_autocomplete_container-input-border: #CCCCCC;
  --react_autocomplete_container-a-div-color: #FFFFFF;
  --react_autocomplete_container-a-div-border: #FFFFFF;
  --react_autocomplete_container-a-div-background-color: #05375e;
  --react_autocomplete_container-a-div-hover-background-color: #05375e;
  --simpleButton-background-color: #00B0B9;
  --simpleButton-color: #FFFFFF;
  --simpleButton-hover-background-color: #00B0B9;
  --simpleButton-selected-background-color: #00B0B9;
  --simpleButton-selected-box-shadow: rgba(0, 0, 0, 0.3);
}
html[data-theme="Light"] {
  --addExtension-border: #fff;
  --addExtension-extensionRow-color: #fff;
  --addExtension-extensionRow-border-bottom: #fff;
  --addExtension-extensionRow-button-background-color: #1D4B6E;
  --addExtension-labelCell-border-right: #cccccc;
  --addExtension-after-border-color: rgba(136, 183, 213, 0);
  --addExtension-after-border-bottom-color: #05375E;
  --addExtension-before-border-color: rgba(194, 225, 245, 0);
  --addExtension-before-border-bottom-color: #c2e1f5;
  --maintenance-card-main-body-webkit-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-moz-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-header-background-color: #FFFFFF;
  --maintenance-card-body-border: #FFFFFF;
  --maintenance-card-header-title-color: #000000;
  --filter_title-color: #000000;
  --react_autocomplete_container-input-background-color: #EBEDED;
  --react_autocomplete_container-input-border: #000000;
  --react_autocomplete_container-a-div-color: #FFFFFF;
  --react_autocomplete_container-a-div-border: #FFFFFF;
  --react_autocomplete_container-a-div-background-color: #FFFFFF;
  --react_autocomplete_container-a-div-hover-background-color: #EBEDED;
  --simpleButton-background-color: #FFFFFF;
  --simpleButton-color: #000000;
  --simpleButton-hover-background-color: #888787;
  --simpleButton-selected-background-color: #636363;
  --simpleButton-selected-box-shadow: rgba(0, 0, 0, 0.3);
}
html[data-theme="Dark"] {
  --addExtension-border: #fff;
  --addExtension-extensionRow-color: #fff;
  --addExtension-extensionRow-border-bottom: #fff;
  --addExtension-extensionRow-button-background-color: #1D4B6E;
  --addExtension-labelCell-border-right: #cccccc;
  --addExtension-after-border-color: rgba(136, 183, 213, 0);
  --addExtension-after-border-bottom-color: #05375E;
  --addExtension-before-border-color: rgba(194, 225, 245, 0);
  --addExtension-before-border-bottom-color: #c2e1f5;
  --maintenance-card-main-body-webkit-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-moz-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-header-background-color: #000000;
  --maintenance-card-body-border: #000000;
  --maintenance-card-header-title-color: #f6f6f6;
  --filter_title-color: #EBEDED;
  --react_autocomplete_container-input-background-color: #000000;
  --react_autocomplete_container-input-border: #CCCCCC;
  --react_autocomplete_container-a-div-color: #000000;
  --react_autocomplete_container-a-div-border: #000000;
  --react_autocomplete_container-a-div-background-color: #000000;
  --react_autocomplete_container-a-div-hover-background-color: #EBEDED;
  --simpleButton-background-color: #000000;
  --simpleButton-color: #FFFFFF;
  --simpleButton-hover-background-color: #888787;
  --simpleButton-selected-background-color: #636363;
  --simpleButton-selected-box-shadow: rgba(0, 0, 0, 0);
}
.underDevelopmentText {
  position: absolute;
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #05375e;
  font-weight: 600;
}
.with-download-icon div .ReactTable .rt-thead .rt-th.-sort-asc:last-child::after {
  content: " ";
  display: none;
}
.with-download-icon div .ReactTable .rt-thead .rt-th.-sort-desc:last-child::after {
  content: " ";
  display: none;
}
.with-download-icon div .ReactTable .rt-thead .rt-th:last-child.-sort-asc div p::after {
  content: " \279C";
  display: inline-block;
  transform: rotate(270deg);
  margin-left: 6px;
}
.with-download-icon div .ReactTable .rt-thead .rt-th:last-child.-sort-desc div p::after {
  content: " \279C";
  display: inline-block;
  transform: rotate(90deg);
  margin-left: 6px;
}
.MuiPopover-root,
.performa-popup-overlay {
  z-index: 99998 !important;
}
.MuiBox-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.Toastify__toast {
  border-radius: 10px !important;
}
.Toastify__toast .toast-title {
  line-height: 22px;
  font-size: 0.8rem;
}
.Toastify__toast .toast-sub-message {
  line-height: 25px;
  font-size: 0.8rem;
}
.Toastify__toast--error {
  background: #d03e2fed;
}
.Toastify__toast-container {
  padding: 10px !important;
}
.rect-bar,
.line-circle {
  cursor: pointer;
}
.headerIcon {
  cursor: pointer;
}
.modal {
  font-size: 12px;
}
.modal > .header {
  width: 98%;
  border-bottom: 1px solid #000000;
  font-size: 18px;
  text-align: left;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.border-input input {
  border: 1px solid #c7c7c7 !important;
}
.content .labelCell {
  font-size: 0.9rem;
}
.content .labelCell:not(.notRequired):after {
  content: "*";
  padding: 3px;
  color: red;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 3px;
  font-size: 24px;
}
.popup-content {
  border-radius: 10px;
  background: #f5f8fb !important;
  color: #000000 !important;
  width: 80% !important;
  border-color: #d8dce0 !important;
}
.margin-top-bottom {
  margin: 0.2rem auto;
}
.tableContainer .form_table_title {
  font-size: 0.9rem;
}
.tableContainer .form_table_value_label,
.tableContainer .form_table_display_label {
  font-size: 0.8rem;
}
.maintenance-card-main-body .maintenance-card-header-title {
  font-size: 1.1rem;
}
@media only screen and (min-width: 48em) {
  .popup-content {
    width: 80% !important;
  }
}
@media only screen and (min-width: 64em) {
  .tableContainer .form_table_title {
    font-size: 1rem;
  }
  .tableContainer .form_table_value_label,
  .tableContainer .form_table_display_label {
    font-size: 0.9rem;
  }
  .maintenance-card-main-body .maintenance-card-header-title {
    font-size: 1.2rem;
  }
  .popup-overlay .performa-popup-overlay .popup-content {
    width: 80% !important;
  }
  .popup-content {
    width: 80% !important;
  }
  .right-box-padding {
    padding-right: 0.8rem;
    padding-left: 0.8rem;
  }
  .form_table {
    padding-right: 0.8rem;
    padding-left: 0.8rem;
    padding-bottom: 0.8rem;
  }
  .section_header_title_label {
    font-size: 1.2rem;
  }
  .margin-top-bottom {
    margin: 0.5rem auto;
  }
}
@media only screen and (min-width: 75em) {
  .tableContainer .form_table_title {
    font-size: 1rem;
  }
  .tableContainer .form_table_value_label,
  .tableContainer .form_table_display_label {
    font-size: 0.9rem;
  }
  .section_header_title_label {
    font-size: 1.2rem;
  }
  .maintenance-card-main-body .maintenance-card-header-title {
    font-size: 1.2rem;
  }
  .popup-overlay .performa-popup-overlay .popup-content {
    width: 80% !important;
  }
  .popup-content {
    width: 80% !important;
  }
  .margin-top-bottom {
    margin: 0.5rem auto;
  }
  .filter-box {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .margin-left-right {
    margin-left: 0.8rem !important;
    margin-right: 0.8rem !important;
  }
}
.no-padding {
  padding: 0rem !important;
}
.card-container {
  padding: 0.5rem;
  min-height: 100vh;
}
.row.content {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}
.content {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}
.content-text {
  line-height: 1.3rem;
}
.container-fluid,
.container {
  margin-right: auto;
  margin-left: auto;
}
.container-fluid {
  padding-right: 2rem;
  padding-left: 2rem;
}
.row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0rem;
  margin-left: 0rem;
}
.row.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.col.reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.col-xs {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
}
.col-xs-1 {
  -ms-flex-preferred-size: 8.33333333%;
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}
.col-xs-2 {
  -ms-flex-preferred-size: 16.66666667%;
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}
.col-xs-3 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
}
.col-xs-4 {
  -ms-flex-preferred-size: 33.33333333%;
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}
.col-xs-5 {
  -ms-flex-preferred-size: 41.66666667%;
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}
.col-xs-6 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}
.col-xs-7 {
  -ms-flex-preferred-size: 58.33333333%;
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}
.col-xs-8 {
  -ms-flex-preferred-size: 66.66666667%;
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}
.col-xs-9 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%;
}
.col-xs-10 {
  -ms-flex-preferred-size: 83.33333333%;
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}
.col-xs-11 {
  -ms-flex-preferred-size: 91.66666667%;
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}
.col-xs-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}
.col-xs-offset-0 {
  margin-left: 0;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.start-xs {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: start;
}
.center-xs {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.end-xs {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: end;
}
.top-xs {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.middle-xs {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.bottom-xs {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.around-xs {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.between-xs {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.first-xs {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}
.last-xs {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
@media only screen and (min-width: 48em) {
  .container {
    width: 49rem;
  }
  .content {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-offset-0,
  .col-sm-offset-1,
  .col-sm-offset-2,
  .col-sm-offset-3,
  .col-sm-offset-4,
  .col-sm-offset-5,
  .col-sm-offset-6,
  .col-sm-offset-7,
  .col-sm-offset-8,
  .col-sm-offset-9,
  .col-sm-offset-10,
  .col-sm-offset-11,
  .col-sm-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .col-sm {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-sm-offset-0 {
    margin-left: 0;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .start-sm {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-sm {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .end-sm {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-sm {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .middle-sm {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .bottom-sm {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .around-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .between-sm {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .first-sm {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .last-sm {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
@media only screen and (min-width: 64em) {
  .container {
    width: 65rem;
  }
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-offset-0,
  .col-md-offset-1,
  .col-md-offset-2,
  .col-md-offset-3,
  .col-md-offset-4,
  .col-md-offset-5,
  .col-md-offset-6,
  .col-md-offset-7,
  .col-md-offset-8,
  .col-md-offset-9,
  .col-md-offset-10,
  .col-md-offset-11,
  .col-md-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .col-md {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-md-offset-0 {
    margin-left: 0;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .start-md {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-md {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .end-md {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-md {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .middle-md {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .bottom-md {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .around-md {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .between-md {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .first-md {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .last-md {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
.body {
  overflow-y: scroll;
}
@media only screen and (min-width: 75em) {
  .container {
    --scrollbar-width: calc(100vw - 100%);
    width: 100%;
  }
  .content {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-0,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .col-lg {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-lg-offset-0 {
    margin-left: 0;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .start-lg {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-lg {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .end-lg {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-lg {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .middle-lg {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .bottom-lg {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .around-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .between-lg {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .first-lg {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .last-lg {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
.filter-label .MuiFormControl-root {
  margin-top: 4px !important;
}
.evenRow {
  background-color: #ffff !important;
  color: var(--table-row-even-font-color) !important;
}
.evenRow td {
  color: #004ea8 !important;
}
.evenRow svg {
  fill: var(--table-row-even-font-color) !important;
}
.oddRow {
  background-color: #f5f5f5 !important;
  color: var(--table-row-odd-font-color) !important;
}
.oddRow td {
  color: #004ea8 !important;
}
.oddRow svg {
  fill: var(--table-row-odd-font-color) !important;
}
a {
  text-decoration: none;
  transition: color 0.3s ease;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace !important;
}
.MuiTypography-body1 {
  font-family: roboto !important;
  line-height: 1 !important;
}
/* Flex styles */
.Row_Flex {
  display: flex;
  flex-direction: row;
}
.Rev_Row_Flex {
  display: flex;
  flex-direction: row-reverse;
}
.Col_Flex {
  display: flex;
  flex-direction: column;
}
.Rev_Col_Flex {
  display: flex;
  flex-direction: column-reverse;
}
.Flex_1 {
  flex: 1;
}
.Flex_2 {
  flex: 2;
}
.Flex_3 {
  flex: 3;
}
.Flex_4 {
  flex: 4;
}
.Flex_5 {
  flex: 5;
}
.Vertical_Middle {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.Horizontal_Middle {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.table-odd {
  background-color: var(--white--widgets-reports);
}
.table-even {
  background-color: var(--even--color-row);
}
.MuiTableCell-head,
.MuiTableSortLabel-root.MuiTableSortLabel-active,
.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
  background-color: white !important;
  color: #004ea8 !important;
}
.MuiTableCell-root {
  font-size: 0.775rem !important;
}
.MuiTableCell-sizeSmall {
  padding: 2px 2px 2px 2px !important;
}
.MuiTableCell-sizeSmall p {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
tr td:nth-child(1),
th:nth-child(1) {
  padding-left: 5px !important;
}
.addExtraSpace {
  padding-left: 20px;
}
.MuiTableSortLabel-root:hover {
  color: #c1c1c1 !important;
}
.MuiTableCell-body {
  color: var(--common--Text--color) !important;
}
.MuiTablePagination-toolbar {
  color: #003d69 !important;
}
.legendText {
  color: var(--common--Text--color) !important;
  font-weight: 400;
}
.MuiListItem-button {
  cursor: pointer !important;
}
.MuiPaper-elevation0 {
  overflow: hidden;
}
.MuiIconButton-label svg:not(.white-button) {
  fill: #00b0b9;
}
.disabed-MuiIconButton-label svg:not(.white-button) {
  fill: #ffff;
}
.disabed-checkbox-MuiIconButton-label svg:not(.white-button) {
  fill: grey;
}
header > svg.MuiSvgIcon-root {
  fill: white !important;
}
.white-button svg,
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-edgeStart svg {
  fill: white !important;
}
#primary-search-account-menu,
#primary-search-account-menu-mobile {
  z-index: 99999999 !important;
}
.d3-tip {
  font-weight: 400;
  font-size: 12px;
  font-weight: bold;
  padding: 12px;
  background: var(--common--Text--color);
  color: #fff;
  border-radius: 5px;
  z-index: 9999999;
  max-width: 300px;
  word-break: break-all;
  word-wrap: break-word;
  line-height: 12px;
}
/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 10px;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  z-index: 9999999;
}
/* Style northward tooltips differently */
.d3-tip.n:after {
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
  line-height: 12px;
}
input,
.MuiInput-input {
  color: var(--common--Text--color);
}
.MuiInput-root {
  position: relative;
  background-color: #ffffff;
}
.MuiListItem-root {
  color: var(--common--Text--color);
}
.condition-heading {
  color: var(--common--Text--color);
  font-weight: bold;
  border-bottom: 1px solid var(--common--Text--color);
  padding: 5px;
  padding-left: 10px;
}
.condition-label {
  color: var(--common--Text--color);
  font-weight: normal;
  font-size: 0.867rem;
  padding-left: 10px;
  line-height: 1rem;
  margin-bottom: 10px;
  margin-top: 5px;
}
.loader-inner {
  top: 45vh;
  left: 50vw;
  position: fixed;
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #003865;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  z-index: 999999999;
}
.small-font {
  font-size: 12px !important;
  text-align: left;
  margin-left: 10px;
}
.small-font span {
  font-size: 12px !important;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.custom-toolbar {
  min-height: 20px !important;
}
.date-text {
  display: inline-block;
  min-width: 68px;
}
.default-text {
  display: inline-block;
  min-width: 68px;
}
.headcell-number-text {
  display: inline-block;
  min-width: 50px;
  text-align: right !important;
  padding-right: 30px;
}
.number-text {
  display: inline-block;
  min-width: 50px;
  text-align: right;
  width: 100%;
  padding-right: 30px;
}
.edit-number-text {
  display: inline-block;
  min-width: 50px;
  text-align: right !important;
}
.report-number-text {
  display: inline-block;
  min-width: 50px;
  text-align: right;
  width: 100%;
  padding-right: 2px;
}
.percent-text {
  display: inline-block;
  min-width: 50px;
  text-align: right !important;
  padding-right: 30px;
}
.edit-percent-text {
  display: inline-block;
  min-width: 50px;
  text-align: right !important;
  padding-right: 30px;
}
.report-percent-text {
  display: inline-block;
  min-width: 50px;
  text-align: right;
  width: 100%;
  padding-right: 2px;
}
.filterPane-action {
  margin-left: 25px;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 10px;
  margin-inline-end: 0px;
  float: right;
  color: var(--common--Text--color);
  font-weight: bold;
}
.field-control {
  padding-bottom: 0px;
}
.filled-hidden-label-small {
  font-weight: bold;
}
.table-text {
  color: #fff;
}
.editable input,
.editable .MuiInputBase-root {
  background-color: #ffffff !important;
  color: black !important;
  padding: 3px;
}
.editable svg {
  fill: var(--common--Text--color) !important;
}
.MuiDialog-paperScrollPaper {
  max-height: calc(100% - 128px) !important;
}
.tracker-item-update-form {
  padding: 10px;
}
.form-section {
  /* Force new columns */
}
.form-section .container {
  display: flex;
  flex-flow: column wrap;
  /* Style your items */
}
.form-section .container .my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -10px;
  /* gutter size offset */
  width: 100%;
}
.form-section .container .my-masonry-grid_column {
  padding-left: 5px;
  /* gutter size */
  background-clip: padding-box;
}
.form-section .container .my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 5px;
}
.form-section .container .MuiSvgIcon-root {
  font-size: 1rem !important;
}
.form-section .custom-section {
  height: 380px;
  overflow: auto;
  width: 100%;
}
.form-section .default-text-span {
  display: inline-block;
  min-width: 68px;
}
.form-section li.MuiTimelineItem-root {
  min-height: 40px !important;
}
.form-section li.MuiTimelineItem-root h3 {
  font-size: 0.768rem !important;
  font-weight: bold;
}
.form-section .hideline .tick line {
  visibility: hidden;
}
.form-section svg.MuiSvgIcon-root {
  font-size: 1rem !important;
}
.form-section .MuiCheckbox-root {
  padding: 1px !important;
}
.form-section .margin-top {
  margin-top: 10px;
}
.form-section .MuiTypography-colorTextPrimary {
  color: var(--common--Text--color) !important;
}
.form-section .MuiTimelineSeparator-root .completed {
  background-color: green;
}
.form-section .MuiTimelineSeparator-root .completed svg {
  fill: white;
}
.form-section .MuiTimelineSeparator-root .notapplicable {
  background-color: #09d809;
}
.form-section .MuiTimelineSeparator-root .notapplicable svg {
  fill: white;
}
.MuiFormControl-marginNormal {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.MuiInputLabel-animated {
  font-size: 0.768rem !important;
}
.MuiChip-sizeSmall {
  height: 22px !important;
}
.chip-input div[class*="WAMuiChipInput-chipContainer-"] {
  background-color: white !important;
}
.chip-input .MuiChip-root {
  background-color: var(--common--Text--color);
}
.chip-input .MuiChip-root span {
  color: white !important;
}
.chip-input .MuiChip-root svg {
  fill: white;
}
iframe body {
  height: 100vh;
}
.report iframe {
  border: none !important;
}
.gc-bar {
  width: 100%;
  overflow: hidden;
}
.gc-center .gc-bar {
  display: inline-block;
  width: auto;
}
.gc-bar .gc-bar-item {
  padding: 8px 16px;
  float: left;
  width: auto;
  border: none;
  display: block;
  outline: 0;
}
.gc-bar .gc-dropdown-hover,
.gc-bar .gc-dropdown-click {
  position: static;
  float: left;
}
.gc-bar .gc-button {
  white-space: normal;
  background-color: #064473;
  border-radius: 14px 14px 0px 0px;
  color: #ffffff;
  margin-left: 2px;
  text-align: center;
  cursor: pointer;
}
.gc-bar .gc-button-selected {
  white-space: normal;
  background-color: #23608e;
  border-radius: 14px 14px 0px 0px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}
.gc-bar-block .gc-bar-item {
  width: 100%;
  display: block;
  padding: 12px 16px;
  text-align: left;
  border: none;
  white-space: normal;
  float: none;
  outline: 0;
}
.gc-bar-block.gc-center .gc-bar-item {
  text-align: center;
}
.gc-block {
  display: block;
  width: 100%;
}
.gc-button:hover {
  color: #000 !important;
  background-color: #23608e !important;
  border-radius: 14px 14px 0px 0px;
  cursor: pointer;
}
.ConfigureMenu .MuiButtonBase-root .MuiListItemText-root span {
  font-size: 0.868rem !important;
}
html,
body {
  height: 100vh !important;
}
.field-container {
  padding: 5px !important;
  padding-top: 0px !important;
  border-radius: 6px;
}
.approval-text span {
  font-size: 0.768rem !important;
}
.approval-text p {
  font-size: 0.6rem !important;
}
.table-list-container {
  margin-top: 20px;
  padding-right: 15px;
}
embed {
  position: fixed !important;
}
.chartBlock {
  background: var(--graph-block-bg);
  border-radius: 2px;
}
@media print {
  .chartBlock .report {
    height: auto !important;
    margin-top: 0 !important;
  }
}
.MuiAppBar-positionFixed {
  height: 40px;
}
.no-data-found-block {
  padding: 10px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
