.layout-header-container {
  display: flex;
  width: 100%;
  z-index: 99995;
  position: fixed;
}
.layout-body-wrapper {
  display: flex;
}
.layout-selected-route-container {
  width: calc(100vw - 73px);
  min-height: calc(100vh - 104px);
  margin-top: 62px;
  margin-bottom: 42px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 5px;
}
