.spec-summary {
  justify-content: flex-start;
  padding: 5px;
  align-items: center;
}
.spec-summary .select-boxes {
  display: inline-flex;
}
.spec-summary .select-boxes .MuiAutocomplete-root {
  margin: 0 5px;
  width: 100% !important;
}
.spec-summary .custom-slider-grp {
  text-align: center;
}
