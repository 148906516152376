html {
  --addExtension-border: #fff;
  --addExtension-extensionRow-color: #fff;
  --addExtension-extensionRow-border-bottom: #fff;
  --addExtension-extensionRow-button-background-color: #1D4B6E;
  --addExtension-labelCell-border-right: #cccccc;
  --addExtension-after-border-color: rgba(136, 183, 213, 0);
  --addExtension-after-border-bottom-color: #05375E;
  --addExtension-before-border-color: rgba(194, 225, 245, 0);
  --addExtension-before-border-bottom-color: #c2e1f5;
  --maintenance-card-main-body-webkit-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-moz-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-header-background-color: #365E7E;
  --common-Rpt--even--color: #004EA8;
  --maintenance-card-body-border: #365E7E;
  --maintenance-card-header-title-color: #f6f6f6;
  --filter_title-color: #4c4c4c;
  --react_autocomplete_container-input-background-color: #EBEDED;
  --react_autocomplete_container-input-border: #CCCCCC;
  --react_autocomplete_container-a-div-color: #FFFFFF;
  --react_autocomplete_container-a-div-border: #FFFFFF;
  --react_autocomplete_container-a-div-background-color: #05375e;
  --react_autocomplete_container-a-div-hover-background-color: #05375e;
  --simpleButton-background-color: #00B0B9;
  --simpleButton-color: #FFFFFF;
  --simpleButton-hover-background-color: #00B0B9;
  --simpleButton-selected-background-color: #00B0B9;
  --simpleButton-selected-box-shadow: rgba(0, 0, 0, 0.3);
}
html[data-theme="Light"] {
  --addExtension-border: #fff;
  --addExtension-extensionRow-color: #fff;
  --addExtension-extensionRow-border-bottom: #fff;
  --addExtension-extensionRow-button-background-color: #1D4B6E;
  --addExtension-labelCell-border-right: #cccccc;
  --addExtension-after-border-color: rgba(136, 183, 213, 0);
  --addExtension-after-border-bottom-color: #05375E;
  --addExtension-before-border-color: rgba(194, 225, 245, 0);
  --addExtension-before-border-bottom-color: #c2e1f5;
  --maintenance-card-main-body-webkit-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-moz-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-header-background-color: #FFFFFF;
  --maintenance-card-body-border: #FFFFFF;
  --maintenance-card-header-title-color: #000000;
  --filter_title-color: #000000;
  --react_autocomplete_container-input-background-color: #EBEDED;
  --react_autocomplete_container-input-border: #000000;
  --react_autocomplete_container-a-div-color: #FFFFFF;
  --react_autocomplete_container-a-div-border: #FFFFFF;
  --react_autocomplete_container-a-div-background-color: #FFFFFF;
  --react_autocomplete_container-a-div-hover-background-color: #EBEDED;
  --simpleButton-background-color: #FFFFFF;
  --simpleButton-color: #000000;
  --simpleButton-hover-background-color: #888787;
  --simpleButton-selected-background-color: #636363;
  --simpleButton-selected-box-shadow: rgba(0, 0, 0, 0.3);
}
html[data-theme="Dark"] {
  --addExtension-border: #fff;
  --addExtension-extensionRow-color: #fff;
  --addExtension-extensionRow-border-bottom: #fff;
  --addExtension-extensionRow-button-background-color: #1D4B6E;
  --addExtension-labelCell-border-right: #cccccc;
  --addExtension-after-border-color: rgba(136, 183, 213, 0);
  --addExtension-after-border-bottom-color: #05375E;
  --addExtension-before-border-color: rgba(194, 225, 245, 0);
  --addExtension-before-border-bottom-color: #c2e1f5;
  --maintenance-card-main-body-webkit-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-moz-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-header-background-color: #000000;
  --maintenance-card-body-border: #000000;
  --maintenance-card-header-title-color: #f6f6f6;
  --filter_title-color: #EBEDED;
  --react_autocomplete_container-input-background-color: #000000;
  --react_autocomplete_container-input-border: #CCCCCC;
  --react_autocomplete_container-a-div-color: #000000;
  --react_autocomplete_container-a-div-border: #000000;
  --react_autocomplete_container-a-div-background-color: #000000;
  --react_autocomplete_container-a-div-hover-background-color: #EBEDED;
  --simpleButton-background-color: #000000;
  --simpleButton-color: #FFFFFF;
  --simpleButton-hover-background-color: #888787;
  --simpleButton-selected-background-color: #636363;
  --simpleButton-selected-box-shadow: rgba(0, 0, 0, 0);
}
.underDevelopmentText {
  position: absolute;
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #05375e;
  font-weight: 600;
}
.spec-suite-info {
  border: 1px solid var(--addLoanForm-addRow-border-bottom);
}
.spec-suite-info .popup-header {
  font-size: 1.3rem;
  padding: 5px 10px 0px 10px;
  font-weight: 600;
  color: #00b0b9;
}
.spec-suite-info .info-text {
  font-size: 1rem;
  font-weight: 500;
  padding: 5px 5px;
  text-align: center;
}
.spec-suite-info .react-datepicker-wrapper {
  width: 100%;
}
.spec-suite-info input {
  text-align: left;
}
.spec-suite-info select {
  background: var(--addLoanForm-select-background);
  color: #000000;
}
.spec-suite-info .headerIcon svg {
  color: #ffffff;
}
.spec-suite-info .addRow {
  display: flex;
  color: var(--addLoanForm-addRow-color);
  font-size: 0.9rem;
  border-bottom: 2px solid var(--addLoanForm-addRow-border-bottom);
  justify-content: left;
  width: 100%;
}
.spec-suite-info .labelCell,
.spec-suite-info .valueCell {
  flex: 1;
  background-color: var(--addLoanForm-labelCell-background-color);
  padding: 0px 3px 0px 0px;
  line-height: 20px;
  font-size: 0.8rem;
}
.spec-suite-info .labelCell:not(.notRequired):after {
  content: "*";
  padding: 3px;
  color: red;
}
.spec-suite-info .removeContent:not(.notRequired):after {
  content: none !important;
}
.spec-suite-info .valueCell {
  background-color: var(--addLoanForm-valueCell-background-color);
}
.spec-suite-info .valueCell .Radiocontainer {
  color: #000000;
  font-weight: 500;
}
.spec-suite-info .valueCell input.outlineAnim,
.spec-suite-info .valueCell select.outlineAnim {
  outline: solid 2px #FC5185;
}
