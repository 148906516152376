.download-progress {
  position: fixed;
  background-color: #fdfdfd;
  border-radius: 2px solid #42455d;
  right: 5px;
  bottom: 0px;
  width: 420px;
  z-index: 99999;
}
.download-progress.minimizeBlock {
  width: 300px;
}
.download-progress .download-header {
  height: 40px;
  background: #42455d;
  margin: 0 auto;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
}
.download-progress .download-header svg {
  cursor: pointer;
}
.download-progress .download-header .minimizeIcon svg {
  display: block;
  margin-top: -10px;
}
.download-progress .download-queue {
  font-weight: 600;
  max-height: 268px;
  overflow-y: auto;
}
.download-progress .download-queue .dInfo {
  padding: 15px 10px;
  border-bottom: 1px solid #ccc;
}
.download-progress .download-queue .dInfo svg {
  color: #07bc0c;
}
@media only screen and (max-width: 768px) {
  .download-progress {
    right: 7px;
    bottom: 14px;
    width: 360px;
  }
}
