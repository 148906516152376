.switch__outer__label {
  font-size: 0.768rem;
  text-align: left;
  padding: 4px 6px;
}
.switch {
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  height: 24px;
  margin: 5px 0 5px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 56px;
}
.switch__label {
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 100%;
}
.switch__input {
  opacity: 0;
}
.switch__input:checked ~ .switch__text {
  background: #00B0B9;
}
.switch__input:checked ~ .switch__text:before {
  opacity: 0;
  transform: translateX(200%);
}
.switch__input:checked ~ .switch__text:after {
  opacity: 1;
  transform: translateX(0);
}
.switch__input:checked ~ .switch__handle {
  transform: translateX(32px);
}
.switch__text {
  background-color: #00B0B9;
  border-radius: 50px;
  box-sizing: border-box;
  display: block;
  flex: 0 0 auto;
  height: 24px;
  margin: 0;
  position: absolute;
  top: 0;
  width: 56px;
}
.switch__text:before,
.switch__text:after {
  color: #ffff;
  font-size: 12px;
  font-weight: 700;
  line-height: 25px;
  position: absolute;
  transition: all 0.2s ease-in-out;
  transition-property: transform;
}
.switch__text:before {
  content: attr(data-off);
  right: 8px;
  transform: translateX(0);
}
.switch__text:after {
  color: white;
  content: attr(data-on);
  left: 9px;
  opacity: 0;
  transform: translateX(-200%);
}
.switch__handle {
  background-color: white;
  border-radius: 18px;
  display: block;
  height: 20px;
  margin: 2px;
  padding: 0;
  position: absolute;
  top: 0;
  transition: all 0.2s ease-in-out;
  transition-property: transform;
  width: 20px;
}
.switch--large {
  height: 30px;
  width: 70px;
}
.switch--large .switch__input:checked ~ .switch__handle {
  transform: translateX(40px);
}
.switch--large .switch__text {
  height: 30px;
  width: 70px;
}
.switch--large .switch__text:before,
.switch--large .switch__text:after {
  font-size: 14px;
  line-height: 30px;
  position: absolute;
}
.switch--large .switch__text:before {
  right: 10px;
}
.switch--large .switch__text:after {
  left: 12px;
}
.switch--large .switch__handle {
  height: 26px;
  width: 26px;
}
.switch--small {
  height: 20px;
  width: 100%;
}
.switch--small .switch__input:checked ~ .switch__handle {
  transform: translateX(40px);
}
.switch--small .switch__text {
  height: 20px;
  width: 60px;
}
.switch--small .switch__text:before,
.switch--small .switch__text:after {
  font-size: 0.768rem;
  line-height: 20px;
  position: absolute;
}
.switch--small .switch__text:before {
  right: 10px;
}
.switch--small .switch__text:after {
  left: 12px;
}
.switch--small .switch__handle {
  height: 16px;
  width: 16px;
}
.switch--no-text .switch__text:before,
.switch--no-text .switch__text:after {
  display: none;
}
.switch--success .switch__input:checked ~ .switch__text {
  background: #5ED37A;
}
