html {
  --addExtension-border: #fff;
  --addExtension-extensionRow-color: #fff;
  --addExtension-extensionRow-border-bottom: #fff;
  --addExtension-extensionRow-button-background-color: #1D4B6E;
  --addExtension-labelCell-border-right: #cccccc;
  --addExtension-after-border-color: rgba(136, 183, 213, 0);
  --addExtension-after-border-bottom-color: #05375E;
  --addExtension-before-border-color: rgba(194, 225, 245, 0);
  --addExtension-before-border-bottom-color: #c2e1f5;
  --maintenance-card-main-body-webkit-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-moz-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-header-background-color: #365E7E;
  --common-Rpt--even--color: #004EA8;
  --maintenance-card-body-border: #365E7E;
  --maintenance-card-header-title-color: #f6f6f6;
  --filter_title-color: #4c4c4c;
  --react_autocomplete_container-input-background-color: #EBEDED;
  --react_autocomplete_container-input-border: #CCCCCC;
  --react_autocomplete_container-a-div-color: #FFFFFF;
  --react_autocomplete_container-a-div-border: #FFFFFF;
  --react_autocomplete_container-a-div-background-color: #05375e;
  --react_autocomplete_container-a-div-hover-background-color: #05375e;
  --simpleButton-background-color: #00B0B9;
  --simpleButton-color: #FFFFFF;
  --simpleButton-hover-background-color: #00B0B9;
  --simpleButton-selected-background-color: #00B0B9;
  --simpleButton-selected-box-shadow: rgba(0, 0, 0, 0.3);
}
html[data-theme="Light"] {
  --addExtension-border: #fff;
  --addExtension-extensionRow-color: #fff;
  --addExtension-extensionRow-border-bottom: #fff;
  --addExtension-extensionRow-button-background-color: #1D4B6E;
  --addExtension-labelCell-border-right: #cccccc;
  --addExtension-after-border-color: rgba(136, 183, 213, 0);
  --addExtension-after-border-bottom-color: #05375E;
  --addExtension-before-border-color: rgba(194, 225, 245, 0);
  --addExtension-before-border-bottom-color: #c2e1f5;
  --maintenance-card-main-body-webkit-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-moz-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-header-background-color: #FFFFFF;
  --maintenance-card-body-border: #FFFFFF;
  --maintenance-card-header-title-color: #000000;
  --filter_title-color: #000000;
  --react_autocomplete_container-input-background-color: #EBEDED;
  --react_autocomplete_container-input-border: #000000;
  --react_autocomplete_container-a-div-color: #FFFFFF;
  --react_autocomplete_container-a-div-border: #FFFFFF;
  --react_autocomplete_container-a-div-background-color: #FFFFFF;
  --react_autocomplete_container-a-div-hover-background-color: #EBEDED;
  --simpleButton-background-color: #FFFFFF;
  --simpleButton-color: #000000;
  --simpleButton-hover-background-color: #888787;
  --simpleButton-selected-background-color: #636363;
  --simpleButton-selected-box-shadow: rgba(0, 0, 0, 0.3);
}
html[data-theme="Dark"] {
  --addExtension-border: #fff;
  --addExtension-extensionRow-color: #fff;
  --addExtension-extensionRow-border-bottom: #fff;
  --addExtension-extensionRow-button-background-color: #1D4B6E;
  --addExtension-labelCell-border-right: #cccccc;
  --addExtension-after-border-color: rgba(136, 183, 213, 0);
  --addExtension-after-border-bottom-color: #05375E;
  --addExtension-before-border-color: rgba(194, 225, 245, 0);
  --addExtension-before-border-bottom-color: #c2e1f5;
  --maintenance-card-main-body-webkit-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-moz-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-header-background-color: #000000;
  --maintenance-card-body-border: #000000;
  --maintenance-card-header-title-color: #f6f6f6;
  --filter_title-color: #EBEDED;
  --react_autocomplete_container-input-background-color: #000000;
  --react_autocomplete_container-input-border: #CCCCCC;
  --react_autocomplete_container-a-div-color: #000000;
  --react_autocomplete_container-a-div-border: #000000;
  --react_autocomplete_container-a-div-background-color: #000000;
  --react_autocomplete_container-a-div-hover-background-color: #EBEDED;
  --simpleButton-background-color: #000000;
  --simpleButton-color: #FFFFFF;
  --simpleButton-hover-background-color: #888787;
  --simpleButton-selected-background-color: #636363;
  --simpleButton-selected-box-shadow: rgba(0, 0, 0, 0);
}
.underDevelopmentText {
  position: absolute;
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #05375e;
  font-weight: 600;
}
.download-popup .downloadIconBtn {
  min-width: 36px;
  height: 32px;
  padding: 5px 0px;
  border-radius: 2px;
  background-color: var(--simpleButton-background-color);
  border: 1pt solid var(--simpleButton-border-color);
}
.download-popup .downloadIconBtn:hover {
  background-color: var(--simpleButton-hover-background-color);
}
.download-popup .popup-downloadOptions {
  z-index: 9999;
}
.download-popup .popup-downloadOptions button span {
  text-transform: none;
}
.download-popup .popup-downloadOptions .paperContainer {
  border: 1px solid #ffffff;
  border-color: var(--dpopup-border-colr);
  background-color: var(--dpopup-bg-colr);
}
.download-popup .popup-downloadOptions .paperContainer .downloadOptions {
  padding: 0.7rem;
}
.download-popup .popup-downloadOptions .paperContainer .downloadOptions .controlParent {
  display: flex;
  flex-direction: row;
}
.download-popup .popup-downloadOptions .paperContainer .downloadOptions .controlParent .fieldset {
  border: 1px solid #ffffff;
  border-color: var(--dpopup-border-colr);
}
.download-popup .popup-downloadOptions .paperContainer .downloadOptions .controlParent .fieldset .radioGroup {
  padding: 5px;
}
.download-popup .popup-downloadOptions .paperContainer .downloadOptions .controlParent .fieldset .radioGroup label {
  padding: 3px;
}
.download-popup .popup-downloadOptions .paperContainer .downloadOptions .controlParent .fieldset .radioGroup label span {
  color: var(--dpopup-text-colr);
  padding: 0 0 0 5px;
  font-size: 0.9rem;
}
.download-popup .popup-downloadOptions .paperContainer .downloadOptions .controlParent .fieldset .radioGroup label.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.download-popup .popup-downloadOptions .paperContainer .downloadOptions .controlParent .fieldset .radioGroup svg {
  cursor: pointer;
  color: var(--radio-button-color);
}
@media only screen and (max-width: 480px) {
  .download-popup .popup-downloadOptions .paperContainer .downloadOptions .controlParent {
    flex-direction: column;
  }
}
.download-popup .popup-downloadOptions .paperContainer .download-button {
  background-color: var(--simpleButton-background-color);
  border: 1px solid #fff;
  margin-top: 0.5rem;
  border-color: var(--dpopup-border-colr);
  height: 32px;
}
.download-popup .popup-downloadOptions .paperContainer .download-button:hover {
  background-color: var(--simpleButton-hover-background-color);
}
.download-popup .popup-downloadOptions .paperContainer .download-button span {
  color: var(--dpopup-download-text-color);
}
.download-popup .popup-downloadOptions .paperContainer .reportTypeParent {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 480px) {
  .download-popup .popup-downloadOptions .paperContainer .reportTypeParent {
    margin-left: 0;
    margin-top: 0.5rem;
  }
}
