.card-container .fundsBtnGroup {
  justify-content: flex-start;
  padding: 5px;
  align-items: center;
}
.card-container .fundsBtnGroup .select-boxes-spec {
  display: inline-flex;
}
.card-container .fundsBtnGroup .select-boxes-spec .MuiAutocomplete-root {
  margin: 0 5px;
  width: 100% !important;
}
.card-container .buttonGrp {
  display: flex;
}
.card-container .buttonGrp svg {
  margin: 0 10px;
  cursor: pointer;
}
