.configuration-container .content-header {
  width: 100%;
  font-weight: bold;
  font-size: 0.868rem !important;
  background-color: #00B0B9;
  color: #ffffff;
  text-align: center;
  height: 26px;
  line-height: 26px;
}
.configuration-container .content-header button {
  color: white;
  float: right;
}
.conf-seer .MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.graph-config-wrapper {
  width: 100%;
  padding-bottom: 15px;
}
.graph-config-wrapper .graph-config-header {
  text-align: center;
  font-size: 16px;
  background-color: #00B0B9;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.graph-config-wrapper .graph-config-form-wapper {
  padding: 10px !important;
}
.performance-attr-child .MuiSelect-select.MuiSelect-select {
  font-size: 0.75rem !important;
  padding-bottom: 4px;
}
.ConfigureMenu {
  width: 100% !important;
  padding: 10px !important;
}
.history-dialog .MuiDialog-paper {
  overflow-y: inherit !important;
}
