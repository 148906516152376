/* Used to detect in JavaScript if apps have loaded styles or not. */
:root {
  --reach-menu-button: 1;
}
[data-reach-menu],
[data-reach-menu-popover] {
  display: block;
  position: absolute;
}
[data-reach-menu][hidden],
[data-reach-menu-popover][hidden] {
  display: none;
}
[data-reach-menu-list],
[data-reach-menu-items] {
  display: block;
  white-space: nowrap;
  border: solid 1px hsla(0, 0%, 0%, 0.25);
  background: hsla(0, 0%, 100%, 0.99);
  outline: none;
  min-width: 88px;
  width: 140px;
  font-size: 85%;
}
[data-reach-menu-item] {
  display: block;
  user-select: none;
}
/*
 The dom structure of a MenuLink is reach-menu-item > a,
 so to target all items we can use `data-reach-menu-item`
*/
[data-reach-menu-item] {
  /*
    These are styled in one rule instead of something like a[data-reach-menu-item]
    and li[data-reach-menu-item] so that apps don't have to fight specificity and
    can style both li and a menu items with one rule,
    ie: `[data-selected] { background: red; }`.
    Otherwise they'd have to define two styles, one for a and one for li.
  */
  /* reach-menu-item */
  cursor: pointer;
  /* a */
  display: block;
  color: inherit;
  font: inherit;
  text-decoration: initial;
  /* both */
  padding: 5px 20px;
}
/* pseudo pseudo selector */
[data-reach-menu-item][data-selected] {
  background: hsl(211, 81%, 36%);
  color: white;
  outline: none;
}
