.form_editable_text_field {
  padding: 0 5px;
  display: inline-block;
  border: 1px solid #464646;
  border-radius: 2px;
  box-sizing: border-box;
  height: 1.4rem;
  width: 100%;
  font-size: 1rem;
}
.form_editable_text_field.rightAlign {
  text-align: right;
}
.form_editable_text_field:disabled {
  background: #e7dfdf;
}
.form_editable_text_field:focus {
  outline: none;
}
.form_editable_text_field.beautify {
  box-sizing: border-box;
  outline: none;
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: 10px 10px 10px 10px;
  transition: all 0.1s ease-out;
}
