.sweet-loading {
  z-index: 9999900;
  width: 100%;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
}
.sweet-loading.hide {
  display: none;
}
.sweet-loading .message {
  border: 1px solid #ccc;
  text-align: center;
  padding: 15px;
  font-size: 1.4rem;
  color: #ce1010;
  font-weight: 600;
}
.sweet-loading .loader-bg {
  width: 100%;
  height: 100vh;
  background: #7e7d7d;
  opacity: 0.5;
  position: fixed;
  z-index: 999;
}
