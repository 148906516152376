.downloadingContainer {
  transform: translate(-50%, -50%);
  z-index: 99999;
  /* -----------Download arrow animations-------- */
  /* -----------Upload arrow animations-------- */
}
.downloadingContainer .buttonDownload {
  display: inline-block;
  position: relative;
  padding: 7px 0px;
  color: #07bc0c;
  font-family: sans-serif;
  text-decoration: none;
  font-size: 0.9em;
  text-align: center;
  text-indent: 15px;
}
.downloadingContainer .buttonDownload:hover {
  color: white;
}
.downloadingContainer .buttonDownload:before,
.downloadingContainer .buttonDownload:after {
  content: " ";
  display: block;
  position: absolute;
  top: 52%;
}
.downloadingContainer .buttonDownload:before {
  width: 14px;
  height: 4px;
  border-style: solid;
  border-width: 0 2px 2px;
}
.downloadingContainer .downloading:after {
  width: 0;
  height: 0;
  margin-left: 3px;
  margin-top: -7px;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: transparent;
  border-top-color: inherit;
  animation: downloadArrow 2s linear infinite;
  animation-play-state: running;
}
@keyframes downloadArrow {
  0% {
    margin-top: -7px;
    opacity: 1;
  }
  0.001% {
    margin-top: -15px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    margin-top: 0;
    opacity: 0;
  }
}
.downloadingContainer .uploading:after {
  width: 0;
  height: 0;
  margin-left: 3px;
  margin-top: -7px;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: transparent;
  border-top-color: inherit;
  animation: uploadArrow 2s linear infinite;
  animation-play-state: running;
  transform: rotate(180deg);
}
@keyframes uploadArrow {
  100% {
    margin-top: -7px;
    opacity: 1;
  }
  99% {
    margin-top: -15px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  0% {
    margin-top: 0;
    opacity: 0;
  }
}
.MuiTooltip-popper,
.sweet-loading {
  z-index: 99999999 !important;
}
