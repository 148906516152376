.chartBox {
  display: flex;
  justify-content: center;
}
.chartBox svg {
  -webkit-filter: drop-shadow(0px 3px 3px var(--svg-webkit-filter));
  filter: drop-shadow(0px 3px 3px var(--svg-filter));
}
.chartBox polyline {
  stroke-width: 2px;
  fill: none;
}
.chartBox .percentage text {
  fill: var(--percentage-text-fill);
  font-size: 12px;
}
.chartBox .y-axis,
.chartBox .x-axis,
.chartBox .bar-label {
  font-size: 12px;
}
.chartBox .labels,
.chartBox .values {
  font-size: 14px;
}
@media screen and (max-width: 1300px) {
  .chartBox .labels,
  .chartBox .values {
    font-size: 11px;
  }
}
.chartTitle1 {
  font-weight: 600;
  padding: 3px;
}
