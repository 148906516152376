.appbar- background {
  background-image: url("../../../public/img/common/header-image.jpg");
}
@media (min-width: 600px) {
  .Close-17 {
    width: 57px !important;
  }
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  color: white;
  background-color: #23608e !important;
}
.MuiListItem-root.Mui-selected span,
.MuiListItem-root.Mui-selected:hover span {
  color: white;
}
.icons {
  color: white;
  width: 22.99px;
  height: 22.99px;
}
