.react-datepicker-wrapper {
  width: 100%;
  margin-right: 0.3rem;
}
.react-datepicker-wrapper input {
  border: none;
  border: 1px solid #464646;
  padding: 0 5px;
  text-align: left;
  height: 1.3rem;
  width: 100%;
  font-size: 0.9rem;
  box-sizing: border-box;
}
