html {
  --table-row-even-background-color: #F9F9F9;
  --table-row-even-font-color: #252733;
  --table-row-odd-background-color: #EFF0F5;
  --table-row-odd-font-color: #252733;
  --header-text-color: Black;
  --error--color: #e56e6d;
  --warning--color: #efae62;
  --info--color: #71bef8;
  --success--color: #89c883;
  --BreadCrum--Text--color: #736a6a;
  --common--Text--color: #365E7E --common-Rpt--even--color:#004EA8;
  --text--active--color: #ffffff;
  --text--normal--color: #2b638d;
  --active--background: #2b638d;
  --normal--background: #f9faff;
  --border-reports-nav: #eeecec;
  --reports-body--bg: #f1f5f8;
  --white--widgets-reports: #ffffff;
  --even--color-row: #d6e7f3f2;
  --aliceblue--HandS--: aliceblue;
  --widgets--border--: #dadada;
}
body {
  padding: 0px;
  margin: 0px;
  font-family: "Roboto", sans-serif !important;
  font-size: 14px;
  line-height: 1 !important;
}
.loader-inner-propagate {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99999;
  display: block;
  margin: 0 auto;
  text-align: center;
}
input {
  font-size: 13px !important;
  padding-left: 3px !important;
}
.bold {
  font-weight: bold;
}
.bg-success {
  background-color: var(--success--color);
}
.bg-error {
  background-color: var(--error--color);
}
.bg-warning {
  background-color: var(--warning--color);
}
.bg-info {
  background-color: var(--info--color);
}
.color-success {
  background-color: var(--success--color);
}
.color-error {
  background-color: var(--error--color);
}
.color-warning {
  background-color: var(--warning--color);
}
.color-info {
  background-color: var(--info--color);
}
.tc {
  text-align: center;
}
.w-5 {
  width: 5%;
}
.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-30 {
  width: 30%;
}
.w-35 {
  width: 35%;
}
.w-40 {
  width: 40%;
}
.w-45 {
  width: 45%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-75 {
  width: 75%;
}
.w-80 {
  width: 80%;
}
.w-100 {
  width: 100%;
}
.hr-small-margin {
  margin-top: 4px;
  margin-bottom: 4px;
}
.d-flex {
  display: flex !important;
}
.pt-5 {
  padding-top: 5px;
}
.dc-primary {
  color: #2b638d;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: #e0e0e0;
}
.data-sync-text {
  color: var(--common--Text--color);
  font-weight: bold;
  padding-left: 17px;
}
@media (min-width: 600px) {
  .MuiToolbar-regular {
    min-height: 60px !important;
  }
}
@media (min-width: 600px) {
  .makeStyles-drawerClose-17 {
    width: 57px !important;
  }
  .makeStyles-drawerClose-21 {
    width: 60px !important;
  }
  .jss17 {
    width: 57px !important;
  }
}
.common-colour {
  color: var(--common--Text--color) !important;
}
.MuiToolbar-regular {
  min-height: 60px !important;
}
.MuiInput-underline:before {
  position: fixed !important;
}
.MuiInput-underline:after {
  border-bottom: 0px !important;
}
.makeStyles-paper-21 {
  width: 100%;
  margin-top: -27px !important;
  margin-bottom: 5px;
}
.MuiTooltip-tooltip {
  background-color: black !important;
}
.MuiDrawer-paper {
  top: 48px !important;
  background-color: "#064473" !important;
  flex: 1 0 auto;
  height: calc(100% - 50px) !important;
  display: flex;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(82, 106, 124, 0.31);
  border-radius: 10px;
  cursor: pointer;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #23608e;
  cursor: pointer;
}
.underline {
  text-decoration: underline #2b638d;
}
.widgetIcon {
  margin-top: 9px;
}
.divider-bg {
  background-color: #05375e !important;
  height: 0.5px !important;
  width: 24% !important;
  padding-top: 2px !important;
}
.button-txt {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  background-color: #eff1f8;
  color: #000000 !important;
  background-color: #cedce9 !important;
  margin-left: 4px;
}
.addedit-buttons {
  position: absolute;
  width: "auto";
  height: 34px;
  margin-left: 3px !important;
  margin-right: 3px !important;
  background: #003d69 !important;
  border-radius: 20px !important;
  color: #dde2ff !important;
}
.div-searchBar {
  width: 18%;
  margin-left: 14.5%;
  margin-top: 3%;
}
.span-link {
  cursor: pointer;
  font-size: 0.875rem;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  text-decoration-thickness: "from-font";
  color: #00b0b9 !important;
}
.txt-area {
  overflow: hidden;
  width: 100%;
  height: 227px !important;
  left: 147px;
  margin: 0px;
  resize: none !important;
  background-color: #eff1f8;
  border: 0px;
}
.header-div {
  background-color: #d9d9d6;
  width: 100%;
  height: 44px;
  left: 16px;
  top: 20px;
  margin-bottom: 7px;
}
.label[data-shrink="true"] {
  display: none;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown:hover .menu {
  display: block;
}
.menu {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 8px 5px;
  z-index: 100;
}
.menuSetting {
  width: 450px;
  right: 29px;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 8px 5px;
  z-index: 100;
}
.MuiButton-containedPrimary {
  /* display: flex; */
  /* flex-direction: row; */
  justify-content: center;
  /* align-items: center; */
  padding: 8px 16px;
  /* position: static; */
  /* width: 77px; */
  height: 32px;
  /* left: 1059px; */
  /* top: 0px; */
  border: 2px solid #00b0b9;
  box-sizing: border-box;
  border-radius: 2px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
  color: #00b0b9 !important;
  background-color: #d9d9d6 !important;
  border: 1px solid #00b0b9 !important;
}
.filter-text-field {
  background-color: #ffff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  position: static;
  width: 100%;
  left: 0px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  height: 39px;
  margin: "0px 0px";
}
.MuiButton-label {
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  position: static;
  height: 16px;
  left: 16px;
  top: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #00b0b9;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.MuiInputBase-inputMultiline {
  font-size: 13px !important;
}
.MuiInputBase-input {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.MuiOutlinedInput-input {
  border: none !important;
}
.MuiInputBase-inputAdornedStart {
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space: nowrap;
}
.search-box {
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space: nowrap;
}
.autoSearchedit-box {
  margin-top: 5px !important;
}
.autoSearch-box {
  margin-top: 5px !important;
}
.autosearc-text {
  border-radius: 2px !important;
}
.pageheader-displayName {
  text-overflow: ellipsis !important;
  width: 400px;
  overflow: hidden;
  white-space: nowrap;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
  padding-right: 0px;
}
.MuiAutocomplete-inputRoot {
  padding: 2px !important;
}
.span-mandatory {
  color: red;
  font-size: 14px;
  padding: 4px;
}
.history-label {
  color: #00b0b9 !important;
  font-weight: 600;
  border: 2px grey;
}
.setting-screen {
  width: 900px;
  height: 34vh !important;
  margin-top: 0px !important;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}
.MuiTypography-subtitle1 {
  margin-bottom: 17px !important;
}
.download-cell {
  width: 288px;
  padding: 10px;
}
.download-cell-header {
  width: 30px;
  padding: 16px !important;
}
.import-anchor {
  border: 0px;
  color: blue;
  background-color: #ffff;
  font-size: 16px;
  cursor: pointer;
}
.import-export-btn {
  border: 1px #00b0b9 solid;
  border-radius: 2px;
  background-color: lightgrey;
  /* padding: 4px; */
  margin-left: 8px;
}
.import-button {
  float: right;
  margin-right: 4px;
  margin-top: 15px !important;
  margin-left: 10px !important;
  margin-bottom: 10px;
  border: 0px;
  color: #ffff;
  /* background-color: #004EA8 !important; */
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: center; */
  /* align-items: center; */
  /* padding: 8px 16px; */
  /* position: static; */
  /* height: 32px; */
  /* left: 0px; */
  /* top: 0px; */
  /* border-radius: 2px; */
  /* flex: none; */
  /* order: 0; */
  /* flex-grow: 0; */
  /* margin: 0px 16px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 8px 16px; */
  position: static;
  /* width: 148px; */
  height: 32px;
  left: 0px;
  top: 0px;
  background-color: #00B0B9 !important;
  border-radius: 7px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.disableimport-button {
  float: right;
  margin-right: 4px;
  margin-top: 15px !important;
  margin-left: 10px !important;
  margin-bottom: 10px;
  border: 0px;
  color: #ffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 8px 16px; */
  position: static;
  /* width: 148px; */
  height: 32px;
  left: 0px;
  top: 0px;
  background-color: lightgray !important;
  border-radius: 7px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.tooltip {
  display: none;
}
.tooltip-on-hover:hover + .tooltip {
  display: block;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 15px;
  height: 15px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  margin: 1px;
  border: 2px solid gray;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: gray transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.btn-small {
  padding: 4px 2px !important;
}
.column-mapping-div {
  padding: 5px;
  width: 100%;
}
