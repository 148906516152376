html {
  --addExtension-border: #fff;
  --addExtension-extensionRow-color: #fff;
  --addExtension-extensionRow-border-bottom: #fff;
  --addExtension-extensionRow-button-background-color: #1D4B6E;
  --addExtension-labelCell-border-right: #cccccc;
  --addExtension-after-border-color: rgba(136, 183, 213, 0);
  --addExtension-after-border-bottom-color: #05375E;
  --addExtension-before-border-color: rgba(194, 225, 245, 0);
  --addExtension-before-border-bottom-color: #c2e1f5;
  --maintenance-card-main-body-webkit-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-moz-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-header-background-color: #365E7E;
  --common-Rpt--even--color: #004EA8;
  --maintenance-card-body-border: #365E7E;
  --maintenance-card-header-title-color: #f6f6f6;
  --filter_title-color: #4c4c4c;
  --react_autocomplete_container-input-background-color: #EBEDED;
  --react_autocomplete_container-input-border: #CCCCCC;
  --react_autocomplete_container-a-div-color: #FFFFFF;
  --react_autocomplete_container-a-div-border: #FFFFFF;
  --react_autocomplete_container-a-div-background-color: #05375e;
  --react_autocomplete_container-a-div-hover-background-color: #05375e;
  --simpleButton-background-color: #00B0B9;
  --simpleButton-color: #FFFFFF;
  --simpleButton-hover-background-color: #00B0B9;
  --simpleButton-selected-background-color: #00B0B9;
  --simpleButton-selected-box-shadow: rgba(0, 0, 0, 0.3);
}
html[data-theme="Light"] {
  --addExtension-border: #fff;
  --addExtension-extensionRow-color: #fff;
  --addExtension-extensionRow-border-bottom: #fff;
  --addExtension-extensionRow-button-background-color: #1D4B6E;
  --addExtension-labelCell-border-right: #cccccc;
  --addExtension-after-border-color: rgba(136, 183, 213, 0);
  --addExtension-after-border-bottom-color: #05375E;
  --addExtension-before-border-color: rgba(194, 225, 245, 0);
  --addExtension-before-border-bottom-color: #c2e1f5;
  --maintenance-card-main-body-webkit-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-moz-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-header-background-color: #FFFFFF;
  --maintenance-card-body-border: #FFFFFF;
  --maintenance-card-header-title-color: #000000;
  --filter_title-color: #000000;
  --react_autocomplete_container-input-background-color: #EBEDED;
  --react_autocomplete_container-input-border: #000000;
  --react_autocomplete_container-a-div-color: #FFFFFF;
  --react_autocomplete_container-a-div-border: #FFFFFF;
  --react_autocomplete_container-a-div-background-color: #FFFFFF;
  --react_autocomplete_container-a-div-hover-background-color: #EBEDED;
  --simpleButton-background-color: #FFFFFF;
  --simpleButton-color: #000000;
  --simpleButton-hover-background-color: #888787;
  --simpleButton-selected-background-color: #636363;
  --simpleButton-selected-box-shadow: rgba(0, 0, 0, 0.3);
}
html[data-theme="Dark"] {
  --addExtension-border: #fff;
  --addExtension-extensionRow-color: #fff;
  --addExtension-extensionRow-border-bottom: #fff;
  --addExtension-extensionRow-button-background-color: #1D4B6E;
  --addExtension-labelCell-border-right: #cccccc;
  --addExtension-after-border-color: rgba(136, 183, 213, 0);
  --addExtension-after-border-bottom-color: #05375E;
  --addExtension-before-border-color: rgba(194, 225, 245, 0);
  --addExtension-before-border-bottom-color: #c2e1f5;
  --maintenance-card-main-body-webkit-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-moz-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-main-body-box-shadow: rgba(0, 0, 0, 0.75);
  --maintenance-card-header-background-color: #000000;
  --maintenance-card-body-border: #000000;
  --maintenance-card-header-title-color: #f6f6f6;
  --filter_title-color: #EBEDED;
  --react_autocomplete_container-input-background-color: #000000;
  --react_autocomplete_container-input-border: #CCCCCC;
  --react_autocomplete_container-a-div-color: #000000;
  --react_autocomplete_container-a-div-border: #000000;
  --react_autocomplete_container-a-div-background-color: #000000;
  --react_autocomplete_container-a-div-hover-background-color: #EBEDED;
  --simpleButton-background-color: #000000;
  --simpleButton-color: #FFFFFF;
  --simpleButton-hover-background-color: #888787;
  --simpleButton-selected-background-color: #636363;
  --simpleButton-selected-box-shadow: rgba(0, 0, 0, 0);
}
.underDevelopmentText {
  position: absolute;
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #05375e;
  font-weight: 600;
}
.tabBoxContainer {
  overflow: hidden;
}
@media print {
  .tabBoxContainer {
    border-color: transparent;
  }
}
.tabBoxContainer .infoBoxBlock {
  text-align: center;
  display: inherit;
}
.tabBoxContainer .MuiBox-root {
  padding: 10px 0px;
}
.tabBoxContainer .MuiPaper-root {
  align-items: center;
}
.tabBoxContainer .MuiTab-wrapper {
  font-weight: 600;
  font-size: 0.84rem;
}
.tabBoxContainer .MuiAppBar-root {
  background: rgba(245, 245, 245, 0.2);
}
.tabBoxContainer .MuiAppBar-colorPrimary {
  color: var(--tab-text-color) !important;
}
@media print {
  .tabBoxContainer .MuiAppBar-colorPrimary {
    display: none;
  }
}
.tabBoxContainer .MuiTabs-indicator {
  background-color: var(--tab-indicator-color) !important;
}
@media only screen and (max-width: 480px) {
  .tabBoxContainer .infoBoxBlock {
    text-align: inherit;
    display: inherit;
    text-align: center;
  }
  .tabBoxContainer .MuiPaper-root {
    align-items: inherit;
  }
}
@media print {
  .tabBoxContainer .arBtnGroup {
    display: none;
  }
}
.tabBoxContainer .arBtnGroup .printBlock {
  align-self: center;
  display: flex;
}
.tabBoxContainer .arBtnGroup .printBlock svg {
  cursor: pointer;
  color: var(--text-color);
}
@media only screen and (max-width: 768px) {
  .tabBoxContainer .arBtnGroup .printBlock {
    display: none;
  }
}
@media screen and (min-height: 480px) {
  .tabBoxContainer tbody {
    max-height: 330px;
  }
}
@media screen and (min-height: 626px) {
  .tabBoxContainer tbody {
    max-height: 540px;
  }
}
@media screen and (min-height: 882px) {
  .tabBoxContainer tbody {
    max-height: 600px;
  }
}
